@mixin declare-font-face($font-family, $font-filename, $font-weight : normal, $font-style :normal, $font-stretch : normal){
	@font-face{
		font-family: '#{$font-family}';
        src: url(#{$font-filename}.woff2) format('woff2'),
             url(#{$font-filename}.woff) format('woff');
		// src: url(#{$font-filename}.eot);
		// src: url(#{$font-filename}.eot?#iefix) format('embedded-opentype'),
		// url(#{$font-filename}.woff) format('woff'),
		// url(#{$font-filename}.ttf) format('truetype'),
		// url(#{$font-filename}.svg##{$font-family}) format('svg');
		font-weight: $font-weight;
		font-style: $font-style;
		font-stretch: $font-stretch;
	}
}
@include declare-font-face('Avenir Next', '../../fonts/AvenirNext-Regular', 400);
@include declare-font-face('Avenir Next', '../../fonts/AvenirNext-DemiBold', 500);
@include declare-font-face('DIN Condensed', '../../fonts/DINCondensedBold', 700);
@include declare-font-face('Gotham', '../../fonts/gotham');
@include declare-font-face('Gotham Bold', '../../fonts/gotham-bold');
@include declare-font-face('Gotham Medium', '../../fonts/Gotham-Medium');
@include declare-font-face('Gotham Narrow', '../../fonts/GothamXNarrow-Bold');

%GothamNarrow {
  font-family: 'Gotham Narrow', sans-serif;
  font-weight: 300;
}
%AvenirNextRegular {
  font-family: 'Avenir Next', sans-serif;
  font-weight: 400;
}
%AvenirNextDemiBold {
  font-family: 'Avenir Next', sans-serif;
  font-weight: 500;
}
%DINCondensedBold {
  font-family: 'DIN Condensed', sans-serif;
  font-weight: 700;
}
%GothamBook {
  font-family: 'Gotham', sans-serif;
  font-weight: 300;
}
%Gotham {
  font-family: 'Gotham', sans-serif;
  font-weight: 400;
}
%GothamMedium {
  font-family: 'Gotham Medium', sans-serif;
  font-weight: 500;
}
%GothamBold {
  font-family: 'Gotham Bold', sans-serif;
  font-weight: 500;
}