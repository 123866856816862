/* Custom CSS for the front */
@import "core/variables";
@import "core/fonts";

$background-color_4: #00000040;
$background-color_5: RGBA(50, 187, 197, 0.1);

span.icd:hover {
  cursor: pointer;
}

/*.dividends .dividends-list-files > li a.icd:hover .dividends-list-icon svg *[stroke] {
    stroke: $black; }*/

.webcast-list-video,
.dividends .dividends-list-files > li span.dividends-list-video {
  color: $turquoise-black;
  display: inline-block;
  -webkit-flex: 0 0 210px;
  -ms-flex: 0 0 210px;
  flex: 0 0 210px;
  font-size: 0.77778rem;
  line-height: 1.11111rem;
  letter-spacing: 1px;
  padding-left: 30px;
  position: relative;
  text-transform: uppercase;
  transition: all .2s ease;
  @extend %AvenirNextDemiBold;
}

.dividends .dividends-list-files > li span.dividends-list-video {
  &:hover {
    color: $black;

    .dividends-list-icon svg *[stroke] {
      stroke: $black;
    }
  }

  .dividends-list-icon,
  .webcast-list-icon {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    height: 20px;
    width: 20px;
  }

  .dividends-list-icon {
    svg {
      max-height: 100%;
      max-width: 100%;
        g {
            stroke: $turquoise-black;
        }
    }
    &:hover {
      svg {
        g {
          stroke: $black;
        }
      }
    }
  }
}

.rich-content-text > table {
  & > thead {
    flex: 0 0 330px;
    font-size: 1.16667rem;
    line-height: 1.77778rem;
    letter-spacing: -.5px;
  }

  & > tbody {
    color: rgba(4, 20, 45, 0.5);
    font-size: 1.16667rem;
    line-height: 1.77778rem;
  }
}

.news .news-item-tags span,
.related-projects-item-tag span,
.projects-list-item-tags span,
.news-single-tags span,
.achievements .achievements-tags span,
.projects-maps .infowindow .infowindow-tags span {
  @extend %AvenirNextDemiBold;
}

.news .news-item-tags span:hover,
.related-projects-item-tag span:hover,
.projects-list-item-tags span:hover,
.news-single-tags span:hover,
.achievements .achievements-tags span:hover,
.projects-maps .infowindow .infowindow-tags span:hover {
  cursor: pointer;
}

.news .news-item-tags span,
.achievements .achievements-tags span,
.news-single-infos .news-single-tags span,
.projects-list .projects-list-item-tags span,
.projects-maps .infowindow .infowindow-tags span,
.related-projects .related-projects-item-tags span {
  border: 1px dotted $black;
  border-radius: 0.11111rem;
  color: $black;
  display: block;
  font-size: 0.66667rem;
  line-height: 1.33333rem;
  padding: 0 10px;
  transition: all .3s ease;

  &:hover {
    background-color: $black;
    color: #ffffff;
  }
}

.rich-content-text > table > thead > tr > th {
  text-align: left !important;
}

.history-list strong {
  font-weight: bold;
}

.section.project-details-content h3,
.section.project-details-content h4,
.news-single-content h2,
.news-single-content h3,
.news-single-content h4 {
  @extend %AvenirNextDemiBold;
}

.section.project-details-content h3,
.news-single-content h3 {
  font-size: 2.44444rem;
  line-height: 3rem;
  letter-spacing: -1.5px;
  margin: 0 0 10px;
  position: relative;
}

.section.project-details-content h4,
.news-single-content h4 {
  font-size: 1.16667rem;
  letter-spacing: -.6px;
  line-height: 1.5rem;
}

.section.project-details-content h3,
.section.project-details-content h4 {
  @extend %AvenirNextDemiBold;
}

.section.project-details-content h3 {
  font-size: 2.44444rem;
  line-height: 3rem;
  letter-spacing: -1.5px;
  margin: 0 0 10px;
  position: relative;
}

a.button-white-to-opacity:hover,
a.button-outline-white-to-opacity:hover {
  opacity: 0.6;
}

a.button-white-to-opacity {
  background-color: #ffffff;
  border-color: #ffffff;
  color: $black;
}

a.button-outline-white-to-opacity {
  background-color: transparent;
  border-color: white;
  color: #ffffff;
}

a.button.button-outline-white-to-opacity {
  border-color: white;
}

.section-bubbles-left .publications-list-subtitle,
.section-bubbles-left .publications-list-title {
  width: 290px !important;
}

.talent-career .talent-career-list-item:hover .talent-career-list-visual {
  box-shadow: none !important
}

.video-wrapper .video-trigger:after {
  background-image: none !important;
}

.section-quote .quote-portrait img {
  mix-blend-mode: normal !important;
}

.rich-content-text a {
  color: $turquoise;

  &:hover {
    text-decoration: underline;
  }
}

.pagination .pagination-pages > li span.pagination-pager-dot {
  background-color: transparent;
  color: $turquoise-black;
}

.footer-address {
  .ezrichtext-field {
    h4 {
      font-size: 1.11111rem;
      line-height: 1.66667rem;
      margin: 0 0 10px;
      text-transform: uppercase;
      @extend %AvenirNextDemiBold;
    }

    p {
      font-size: 1.11111rem;
      line-height: 1.66667rem;
    }
  }

  .titre_adresse {
    @extend %GothamBold;
    text-transform: uppercase;
  }
}

.button {
  z-index: 1;
}

.infos h3 {
  font-size: 2.44444rem;
  line-height: 3rem;
  letter-spacing: -1.5px;
  margin: 0 0 10px;
  position: relative;
  @extend %AvenirNextDemiBold;
}

@media (max-width: 768px) {
  .infos h3,
  .section.project-details-content h3,
  .news-single-content h3 {
    font-size: 1.33333rem;
    line-height: 1.55556rem;
    letter-spacing: -.5px;
  }
}

.ytFrame {
  max-width: 640px;
  max-height: 360px;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 420px) {
  .ytFrame {
    max-width: 90%;
  }
}

.faq-list-answer ul,
.faq-list-answer ul li,
.faq-list-answer ol,
.faq-list-answer ol li,
.project-details-content ul,
.project-details-content ul li,
.project-details-content ol,
.project-details-content ol li {
  margin-left: 15px;
}

.faq-list-answer ul,
.faq-list-answer ul li,
.project-details-content ul,
.project-details-content ul li {
  list-style: disc;
}

.faq-list-answer ol,
.faq-list-answer ol li,
.project-details-content ol,
.project-details-content ol li {
  list-style: decimal;
}

@media (max-width: 420px) {
  .questions .questions-slider .questions-slider-visual .questions-slider-visual-img {
    background-position: 50% 17%;
  }
}

.ccContent {
  .btnBloc {
    text-align: center;
  }

  ul {
    padding-left: 2.22222rem;

    li {
      label {
        font-weight: 900;
        margin-bottom: 0.83333rem;
      }

      .desc {
        margin-top: 10px;
        margin-left: 2rem;
      }
    }
  }

  &.rich-content .rich-content-text p {
    max-width: 100%;
  }
}


.homepage-push-item {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.2;
    z-index: 1;
  }

  .homepage-push-infos,
  .news-single-tags .homepage-push-tag {
    z-index: 2;
  }
}

.page-intro-visual-caption {
  @extend %AvenirNextRegular;
  color: rgba(4, 20, 45, 0.45);
  font-size: 0.94444rem;
  line-height: 1.44444rem;
  margin: 15px 0 0 15px;
}

.project-nav {
  span {
    margin: 0 10px;
    position: relative;
    transition: color .3s ease;
  }

  &.projects {
    span:nth-child(1) {
      color: $turquoise;

      &:after {
        height: 2px;
      }
    }
  }

  &.references {
    span:nth-child(2) {
      color: $turquoise;

      &:after {
        height: 2px;
      }
    }
  }

  &.map {
    span:nth-child(3) {
      color: $turquoise;

      &:after {
        height: 2px;
      }
    }
  }

  span:hover {
    color: $turquoise;
  }

  span:after {
    background-color: $turquoise;
    bottom: -21px;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    transition: height .2s ease;
    height: 0;
    width: auto;
  }
}

.talent-reasons .talent-reasons-list {
  .talent-reasons-list-moar.lang_en span:before {
    content: "Read more";
  }

  .talent-reasons-list-item.active .talent-reasons-list-moar.lang_en span:before {
    content: "Close";
  }
}

/* nouveau filtre de recherche */
/* blocks rai defilement */
/* make header cklickable */
/* Hide scrollbar for IE, Edge and Firefox */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bandeau.ccContent {
  box-shadow: -1px -2px 10px #d8d8d8;
}

.bandeau {
  .rich-content-text {
    margin-top: 0 !important;
    padding-left: 20px !important;

    .section-title {
      font-size: 25px;
      margin-top: 10px;
    }

    p {
      text-align: justify;
      font-size: 14px;
      line-height: 18px;
      margin: 10px 30px 10px 0 !important;
    }

    .links {
      display: flex;
      justify-content: center;
      margin: 0;

      li {
        list-style: square;
        margin: 20px;
        width: 320px;

        strong {
          font-size: 15px;
          font-weight: bold;
        }

        a {
          font-size: 14px;
        }
      }
    }

    .actions {
      display: flex;
      justify-content: center;

      a {
        margin: 0 5px;
      }
    }
  }
}

.filters {
  margin: 20px 0;
  text-transform: uppercase;
}

.filters__wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.filters__trigger-mobile {
  display: none;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  border-bottom: 1px dotted #CFD1D5;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;

  button {
    height: auto;
    text-transform: uppercase;
    padding-bottom: 15px;

    svg {
      opacity: 0.5;
    }
  }
}

.filters__left__close-mobile {
  display: none;
  padding: 20px 15px;
  color: $turquoise;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: auto;
  width: 100%;
  border-bottom: 1px dotted #CFD1D5;
  text-transform: uppercase;
  -ms-flex-align: center;
  align-items: center;

  span {
    margin-top: 2px;
  }
}

.filters__left {
  ul {
    li {
      margin-right: 40px;
      display: inline-block;

      a {
        opacity: 0.5;
        transition: all 250ms;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 1px;

        &:hover {
          opacity: 1;
        }
      }

      span {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 1px;
      }
    }
  }
}

.current-ctype {
  a {
    opacity: 1;
  }
}

.tri-asc {
  content: "";
  opacity: .45;
  position: absolute;
  right: 0;
  top: 50%;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease;
  -webkit-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg);
  background-color: transparent;
  background-image: url(../images/dropdown_arrow.svg);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 7px 10px;
  height: 10px;
  width: 7px;
}

.cover-alt-top__content {
  a.button.button-black {
    margin-right: 20px;
    margin-top: 20px;
  }
}

.z_index_2 {
  z-index: 2;
}

.z_index_4 {
  z-index: 4;
}

.sticky-block {
  position: sticky;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
}

.push-immo {
  p {
    margin-bottom: 1.11111rem;
    font-size: 1.16667rem;
    letter-spacing: -.6px;
    line-height: 1.5rem;
    @extend %AvenirNextDemiBold;
  }
}

.section-space-interne {
  margin-top: 0;
  padding-top: 60px;
  background-color: white;
}

.row-intro {
  p {
    &:nth-of-type(2) {
      cursor: none;
      pointer-events: none;
    }
  }
}

.numbers__number {
  + {
    p {
      opacity: .5;
      max-width: 290px;
    }
  }
}

.left-content-righ {
  > h1 {
    color: black;
    font-size: 1.75rem;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.title-left-content-right {
  b {
    font-weight: bolder;
  }

  strong {
    font-weight: bolder;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    line-height: 1.5;
  }
}

.main-nav {
  .main-nav-search {
    .main-nav-search-input {
      input {
        text-transform: none;
      }
    }
  }
}

.quote-peopleauthor {
  img {
    height: 80px;
  }
}

.quote-people__author {
  img {
    height: 80px;
  }
}

.dividends {
  .dividends-list-files {
    margin: 10px 0;
  }
}

.event-featured__btns {
  min-width: 450px;
}

.title-filters {
  .publications-filters-container {
    border-top: 1px dotted #BBC !important;
  }

  .publications-filter-item {
    &:last-child {
      .publications-filter-dropdown {
        left: 0;
        right: auto;
      }
    }
  }
}

.push-block {
  .push-block-row {
    .push-block-bg-blue {
      background-color: $turquoise-third;
    }
  }
}

.push-modal-section {
  .push-modal-background {
    background-color: $background-color_4 !important;
  }
}

.market-table {
  .table-row {
    margin-top: 32px;
    overflow-y: hidden;
  }

  table {
    width: 100%;

    thead {
      tr {
        th {
          border: 1px solid #fff;
          border-bottom: 1px solid RGBA(50, 187, 197, 0.1);
          background-color: $background-color_5;
          padding: 16px 32px;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 17px;
          letter-spacing: 0.56px;
          text-transform: uppercase;

          &:first-child {
            border-left: 1px solid RGBA(50, 187, 197, 0.1);
          }

          &:last-child {
            border-right: 1px solid RGBA(50, 187, 197, 0.1);
          }
        }
      }
    }

    tr {
      td {
        padding: 24px;
        border: 1px solid rgba(4, 20, 45, 0.10);
        text-align: center;
        vertical-align: middle;
        font-size: 17px;
        font-weight: 400;

        a {
          color: $turquoise;
          font-size: 14px;
          font-weight: 600;
          line-height: 17px;
          letter-spacing: 0.56px;
          text-transform: uppercase;
        }
      }
    }

    .table-cta {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .table-cta {
    &:hover {
      a {
        color: black;
      }

      img {
        filter: saturate(0%) brightness(70%) contrast(1000%);
      }
    }
  }
}

.push-text {
  p {
    margin-bottom: 40px;
    font-size: 0.94444rem;
    line-height: 1.44444rem;
  }
  .title-cell {
    margin-bottom: 24px;
  }
}

.publications {
  .publications-list {
    .publications-list-icon {
      img {
        border-radius: 0.22222rem;
        border: 1px solid #BBC;
      }
    }
  }
}

.hero-banner-grid {
  padding-bottom: 107px !important;
}

.next-event {
  .tags-container {
    li.tag {
      margin-right: 10px;
    }
  }
}

.list-events {
  .tags-container {
    li.tag {
      margin-right: 10px;
    }
  }
}

.old-events {
  .tags-container {
    li.tag {
      margin-right: 10px;
    }
  }
}

div.publications-list-info {
  li.tag.publications-list-type {
    margin-right: 10px !important;
  }
}

.info-text {
  .text {
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
  }
}

.map-text-title {
  margin-bottom: 16px !important;
}

.investir-financial-documents {
  .button-cell {
    .button {
      &:hover {
        svg {
          filter: invert(1);
        }
      }
    }
  }
}

.document {
  .document-title {
    margin-bottom: 32px !important;
  }

  .tag {
    &:hover {
      color: $black !important;
      background-color: initial;
    }
  }
}

.projet-cell {
  margin-bottom: 0 !important;
}

.grid-document-list {
  .documents-container {
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.section-title-wrapper {
  .button {
    &::after {
      display: none;
    }
  }
}

.div.gm-style-iw {
  .gm-style-iw-c {
    padding-top: 0 !important;
  }
}

.row-map {
  .cell-map {
    .filter-cell {
      position: absolute;
      top: 20px;
      z-index: 1;
    }
  }
}

.grid-map {
  button.tag-list-button.tag-list-button-loc.button {
    height: 32px;
    padding: 12px 24px !important;
    color: $black;
  }
}

.cell-map {
  div#filterGrid {
    position: absolute;
    top: 20px;
    z-index: 1;
  }

  details.sub-menu-details.filter-details {
    background: white;
    padding: 0 20px;
  }

  summary.sub-menu-summary.filter-summary {
    margin: 0;
  }
}

.sub-menu-details {
  .sub-menu-summary {
    &:after {
      margin-top: 20px;
    }
  }
}

#list-result {
  .loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }
}

.filter-cell.cell-12 {
  .filter-title {
    width: auto !important;
  }
}

@media (max-width: 767px) {
  .visual-citation-picture {
    height: 250px;
  }
  .info-text {
    .text {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

@media screen and (max-width: 420px) {
  .market-table {
    table {
      thead {
        tr {
          th {
            font-size: 12px;
            padding: 12px 16px;
          }
        }
      }

      tr {
        td {
          font-size: 14px;
          padding: 24px 16px;
        }
      }
    }
  }
  #documents-container {
    overflow: scroll !important;
  }
  .investir-financial-documents {
    .button-cell {
      display: none;
    }
  }
  .grid-document-list {
    padding: 0;
  }
  .cells-container {
    .document-cell {
      &:nth-child(1) {
        margin-left: 20px;
      }

      width: 90%;
    }
  }
}

@media (max-width: 768px) {
  .row-map {
    .cell-map {
      .filter-cell {
        display: none;
      }
    }
  }
}
