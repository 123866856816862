$base-font-size: 18px;

/* Colors */

$white: #FFF;
$black: #04142D;
$grey: #CFD1D5;
$border: #BBBBCC;
$grey-second:#E7E8EA;
$grey-third:#DFEDEF;
$grey-fourth:#A8B6BE;

$turquoise-black: #005D71;
$turquoise-black-second: #0085A1;
$turquoise-black-alt: #337D8D;
$turquoise: #32BBC5;
$icade-blue: #75C2BD;
$turquoise-alt:#56C7CF;
$turquoise-third:#EBF9FA;
$bg-turquoise: #e6f3f6;
$bg-turquoise-light: #f3f9f9;

$green: #88CB7E;
$green-second:#28B923;
$yellow: #FBE88C;
$yellow-faded: rgb(255, 250, 230);
$violet: #290264;
$red:#D93939;

$disabled: #F5F5F5;

$brand: $turquoise;

$background-grey: #ECECEC;

$colors: (
        'white':$white,
        'black':$black,
        'grey':$grey,
        'grey-second':$grey-second,
        'grey-third':$grey-third,
        'grey-fourth':$grey-fourth,
        'border':$border,
        'turquoise':$turquoise,
        'turquoise-alt':$turquoise-alt,
        'green':$green,
        'green-second':$green-second,
        'yellow':$yellow,
        'violet':$violet,
        'red':$red,
);

// Build class utils
@each $name, $var in $colors {
  .color-#{$name}{
    color:$var;
  }
  .bg-#{$name}{
    background-color:$var;
  }
}